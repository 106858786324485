.terms-conditions {
  padding: 4rem 2rem;
  background-color: var(--grey-color-light);
}

.terms-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-bottom: 3rem;
}

.terms-content .effective-date {
  color: var(--grey-color-dark);
  font-style: italic;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
}

@media only screen and (max-width: 768px) {
  .terms-conditions {
    padding: 2rem 1rem;
  }
  
  .terms-content {
    padding: 1.5rem;
  }
}

.contact-us a {
  color: #007bff; /* Blue color */
  text-decoration: underline; /* Ensures visibility */
  font-weight: bold;
}

.contact-us a:hover {
  color: #0056b3; /* Darker blue when hovered */
}